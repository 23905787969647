<template>
  <div
    class="wrapper"
    ref="invoiceEditWrap"
    :style="{
      height: `${wrapHeight}px`,
    }"
  >
    <page-loader :loading="loading">
      <div class="d-flex" v-if="!loading">
        <div class="h-100 w-50 mr-1">
          <b-card no-body class="invoice-preview-card">
            <!--- Header --->
            <b-card-body class="invoice-padding pb-0 d-flex">
              <div class="invoice-receiver w-50 mr-2">
                <p class="font-weight-bold">
                  {{ T("Web.Generic.Invoices.Reciver", "Receiver") }}
                </p>
                <p class="card-text mb-25">
                  <b-form-input v-model="invoice.customer.name" />
                </p>
                <p class="card-text mb-25">
                  <b-form-input v-model="invoice.customer.address" />
                </p>
                <p class="card-text mb-25 d-flex">
                  <b-form-input
                    v-model="invoice.customer.zip"
                    style="width: 150px"
                    class="mr-1"
                  />
                  <b-form-input v-model="invoice.customer.city" />
                </p>
                <p class="card-text mb-25">
                  <b-form-input v-model="invoice.customer.cvr" />
                </p>
              </div>
              <div class="invoice-receiver w-50 ml-2">
                <p class="font-weight-bold">
                    {{ T("Web.Generic.Invoices.Sender", "Sender") }}
                </p>
                <p class="card-text mb-25">
                  <b-form-input v-model="invoice.supplier.name" />
                </p>
                <p class="card-text mb-25">
                  <b-form-input v-model="invoice.supplier.address" />
                </p>
                <p class="card-text mb-25 d-flex">
                  <b-form-input
                    v-model="invoice.supplier.zip"
                    style="width: 150px"
                    class="mr-1"
                  />
                  <b-form-input v-model="invoice.supplier.city" />
                </p>
                <p class="card-text mb-25">
                  <b-form-input v-model="invoice.supplier.cvr" />
                </p>
              </div>
            </b-card-body>
            <b-card-body class="d-flex">
              <div class="invoice-date-wrapper w-50 mr-2">
                <p class="invoice-date-title">
                      {{ T("Web.Generic.Invoices.InvoiceDate", "Date") }}
                </p>
                <p class="invoice-date">
                  <b-form-datepicker
                    v-model="invoice.issueDate"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />
                </p>
              </div>
              <div class="w-50 ml-2">
                <p class="invoice-date-title">
                  #{{ getDocumentTypeDisplayText(invoice.documentType) }}
                </p>
                <p class="invoice-date">
                  <b-form-input v-model="invoice.invoiceNumber" />
                </p>
              </div>
            </b-card-body>
            <b-card-body>
              <b-table-simple small class="sumTable text-center">
                <b-thead>
                  <b-tr class="toggle_headers">
                    <b-th>{{ T("Web.Generic.Invoices.LineDate", "Date") }}</b-th>
                    <b-th>{{ T("Web.Generic.Invoices.LineDescription") }}</b-th>
                    <b-th>{{ T("Web.Generic.Invoices.LineAmount", "Amount") }}</b-th>
                    <b-th>{{ T("Web.Generic.Invoices.LineUnit") }}</b-th>
                    <b-th>{{ T("Web.Generic.Invoices.LineUnitPrice") }}</b-th>
                    <b-th>{{ T("Web.Generic.Invoices.LineTotal") }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="line in invoice.invoiceLines" :key="line.id">
                    <b-td>
                      <b-form-datepicker
                        v-model="line.date"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        size="sm"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="line.text"
                        size="sm"
                        style="min-width: 250px"
                      />
                      <b-form-input v-model="line.note" size="sm" />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="line.amount"
                        type="number"
                        size="sm"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input v-model="line.unit" size="sm" />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="line.priceAmount"
                        type="number"
                        size="sm"
                      />
                    </b-td>
                    <b-td>
                      <div class="d-flex align-items-center">
                        <b-form-input
                          v-model="line.priceTotal"
                          type="number"
                          size="sm"
                        />
                        <div>
                          <feather-icon
                            icon="XIcon"
                            class="ml-1 mr-1 text-danger"
                            @click="removeInvoiceLine(line)"
                          />
                        </div>
                      </div>
                    </b-td>
                  </b-tr>
                  <b-tr variant="secondary">
                    <b-td>
                                            <b-form-input v-model="newInvoiceLine.date" size="sm" />
<!--
                      <b-form-datepicker
                        v-model="newInvoiceLine.date"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        size="sm"
                      />
                      -->
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="newInvoiceLine.text"
                        size="sm"
                        style="min-width: 250px"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input v-model="newInvoiceLine.amount" size="sm" />
                    </b-td>
                    <b-td>
                      <b-form-input v-model="newInvoiceLine.unit" size="sm" />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="newInvoiceLine.priceAmount"
                        type="number"
                        size="sm"
                      />
                    </b-td>
                    <b-td>
                      <div class="d-flex align-items-center">
                        <b-form-input
                          v-model="newInvoiceLine.priceTotal"
                          type="number"
                          size="sm"
                        />
                        <div class="ml-1">
                          <b-button
                            size="sm"
                            variant="primary"
                            :disabled="!canAddNewLine"
                            @click="addNewLine"
                            >{{ T("Web.Generic.Add") }}</b-button
                          >
                        </div>
                      </div>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card-body>
          </b-card>
          <b-card class="mb-2 d-flex align-items-end">
            <b-button variant="primary" :disabled="saving" @click="saveInvoice"
              >{{ saving ? T("Web.Generic.Saving") : T("Web.Generic.Save") }}
            </b-button>
          </b-card>
          <br />
        </div>
        <div class="w-50 ml-1 pdf-reviewer" v-if="isPdf">
          <iframe
            class="h-100 w-100"
            :src="pdfUrl"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </page-loader>
  </div>
</template>

<script>
import PageLoader from "@/app/components/layout/PageLoader.vue";
import { mapGetters, mapActions } from "vuex";
import Config from "@/configs";
import Vue from "vue";
import {
  BCard,
  BCardBody,
  BFormInput,
  BFormDatepicker,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BButton,
} from "bootstrap-vue";
import { uuid } from "vue-uuid";
import InvoicesService from "@/services/invoices.service";

export default {
  components: {
    BTableSimple,
    BFormDatepicker,
    PageLoader,
    Config,
    BFormInput,
    BCardBody,
    BCard,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
  },
  async created() {
    await this.fetchInvoice({ id: this.invoiceId, include: ["invoiceLines"] });
    this.loading = false;
  },
  mounted() {
    Vue.nextTick(() => {
      this.updateVhHeight();
      window.addEventListener("resize", () => this.updateVhHeight());
    });
  },
  data() {
    return {
      wrapHeight: 0,
      loading: true,
      saving: false,
      newInvoiceLine: {
        id: uuid.v4(),
        date: "",
        text: "",
        description: "",
        amount: 0,
        unit: "",
        priceAmount: 0,
        priceTotal: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      getInvoice: "economicsInvoices/byId",
      isLoading: "economicsInvoices/isLoading",
    }),
    invoiceId() {
      return this.$route.params.id;
    },
    invoice() {
      return this.getInvoice(this.invoiceId);
    },
    isPdf()
    {
      return this.$route.query.pdf == 'true'  ; 
    },
    pdfUrl() {
      return `${Config.api.hostname}/economics/invoices/${this.invoiceId}/source-file`;
    },
    canAddNewLine() {
      return this.newInvoiceLine.date != "" && this.newInvoiceLine.text != "";
    },
  },
  methods: {
    ...mapActions({ fetchInvoice: "economicsInvoices/fetchSingle" }),
    updateVhHeight() {
      const positionFromTop =
        this.$refs.invoiceEditWrap.getBoundingClientRect().top;
      this.wrapHeight = window.innerHeight - positionFromTop;
    },
    getDocumentTypeDisplayText(type) {
      return this.T(`Web.Generic.Invoices.DocumentTypes.${type}`);
    },
    removeInvoiceLine(line) {
      const indexOfLine = this.invoice.invoiceLines.indexOf(line);
      this.invoice.invoiceLines.splice(indexOfLine, 1);
    },
    addNewLine() {
      this.invoice.invoiceLines.push(this.newInvoiceLine);
      this.newInvoiceLine = {
        id: uuid.v4(),
        date: "",
        text: "",
        description: "",
        amount: 0,
        unit: "",
        priceAmount: 0,
        priceTotal: 0,
      };
    },
    async saveInvoice() {
      try {
        this.saving = true;
        const service = new InvoicesService();
        await service.saveInvoice(this.invoice);
        this.$router.push({
          name: "economics-reconciliation",
          params: { invoiceId: this.invoice.id },
        });
      } catch {
        alert("Unknown error!");
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
}

.invoice-line {
  width: 100%;
  background: red;
  margin-bottom: 5px;
}

.pdf-reviewer {
  position: sticky;
  top: 100px;
  height: calc(100vh - 130px);
}
</style>